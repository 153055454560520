/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Comments } from '../models/Comments';
import type { CommentsData } from '../models/CommentsData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CommentsService {

    /**
     * Get comments
     * @returns CommentsData Comments got response
     * @throws ApiError
     */
    public static getComments({
        limit,
        offset,
    }: {
        /** Limit **/
        limit?: number,
        /** Offset **/
        offset?: number,
    }): CancelablePromise<CommentsData> {
        return __request({
            method: 'GET',
            path: `/comments`,
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * Get comment
     * @returns Comments Comment got response
     * @throws ApiError
     */
    public static getComment({
        id,
    }: {
        /** Comment ID **/
        id: number,
    }): CancelablePromise<Comments> {
        return __request({
            method: 'GET',
            path: `/comment/${id}`,
            errors: {
                500: `Error response`,
            },
        });
    }

}