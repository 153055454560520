/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Article } from '../models/Article';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ArticlesService {

    /**
     * Get article
     * @returns Article Article response
     * @throws ApiError
     */
    public static getArticle({
        path,
    }: {
        /** Article path **/
        path: string,
    }): CancelablePromise<Article> {
        return __request({
            method: 'GET',
            path: `/article/${path}`,
            errors: {
                500: `Error response`,
            },
        });
    }

}