/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderExternalStatus {
    TO_SET_PRICE = 'to_set_price',
    TO_CALL = 'to_call',
    TO_PREPAY = 'to_prepay',
    IN_WORK = 'in_work',
    CANCELLED = 'cancelled',
    SUSPENDED = 'suspended',
    TO_PAY = 'to_pay',
    PAYED = 'payed',
    TO_ACCEPT = 'to_accept',
}