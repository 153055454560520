/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetShopGoods } from '../models/GetShopGoods';
import type { GetShopLessons } from '../models/GetShopLessons';
import type { ShopGood } from '../models/ShopGood';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ShopService {

    /**
     * Get shop lessons
     * @returns GetShopLessons GetShopLessonsResponse
     * @throws ApiError
     */
    public static getShopLessons(): CancelablePromise<GetShopLessons> {
        return __request({
            method: 'GET',
            path: `/shop/lessons`,
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * Get shop goods
     * @returns GetShopGoods GetShopGoodsResponse
     * @throws ApiError
     */
    public static getShopGoods({
        lesson,
    }: {
        /** Lesson **/
        lesson: string,
    }): CancelablePromise<GetShopGoods> {
        return __request({
            method: 'GET',
            path: `/shop/goods`,
            query: {
                'lesson': lesson,
            },
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * Get shop good
     * @returns ShopGood GetShopGoodResponse
     * @throws ApiError
     */
    public static getShopGood({
        id,
    }: {
        /** Good ID **/
        id: number,
    }): CancelablePromise<ShopGood> {
        return __request({
            method: 'GET',
            path: `/shop/good/${id}`,
            errors: {
                404: `Not found`,
                500: `Error response`,
            },
        });
    }

}