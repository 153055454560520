/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserToLoginData } from '../models/UserToLoginData';
import type { UserToRegisterData } from '../models/UserToRegisterData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get user self
     * @returns User UserSelfGot
     * @throws ApiError
     */
    public static getUserSelf(): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/user/self`,
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * LoginUser
     * @returns User UserLoggedin
     * @throws ApiError
     */
    public static loginUser({
        requestBody,
    }: {
        /** UserToLogin **/
        requestBody?: UserToLoginData,
    }): CancelablePromise<User> {
        return __request({
            method: 'PUT',
            path: `/user`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * RegisterUser
     * @returns User UserRegistered
     * @throws ApiError
     */
    public static registerUser({
        requestBody,
    }: {
        /** UserToRegister **/
        requestBody?: UserToRegisterData,
    }): CancelablePromise<User> {
        return __request({
            method: 'POST',
            path: `/user`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Exists`,
                500: `Error response`,
            },
        });
    }

}