/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { OrderAcceptedData } from '../models/OrderAcceptedData';
import type { OrderAddedData } from '../models/OrderAddedData';
import type { OrderID } from '../models/OrderID';
import type { Orders } from '../models/Orders';
import type { OrderToAcceptData } from '../models/OrderToAcceptData';
import type { OrderToAddData } from '../models/OrderToAddData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * Get orders history
     * @returns Orders OrdersHistoryGot
     * @throws ApiError
     */
    public static getOrdersHistory(): CancelablePromise<Orders> {
        return __request({
            method: 'GET',
            path: `/orders/history`,
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * Get order
     * @returns Order OrderGot
     * @throws ApiError
     */
    public static getOrder({
        id,
    }: {
        /** order ID **/
        id: OrderID,
    }): CancelablePromise<Order> {
        return __request({
            method: 'GET',
            path: `/order/${id}`,
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * OrderAccept
     * @returns OrderAcceptedData OrderStatusSet
     * @throws ApiError
     */
    public static orderAccept({
        id,
        requestBody,
    }: {
        /** order ID **/
        id: OrderID,
        /** OrderToAccept **/
        requestBody?: OrderToAcceptData,
    }): CancelablePromise<OrderAcceptedData> {
        return __request({
            method: 'POST',
            path: `/order/accept/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Error response`,
            },
        });
    }

    /**
     * AddOrder
     * @returns OrderAddedData OrderAdded
     * @throws ApiError
     */
    public static addOrder({
        requestBody,
    }: {
        /** OrderToAdd **/
        requestBody?: OrderToAddData,
    }): CancelablePromise<OrderAddedData> {
        return __request({
            method: 'POST',
            path: `/order`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Error response`,
            },
        });
    }

}